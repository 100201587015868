import React, {Component} from 'react';
import Loader from './loader.png';
// import Gallery from 'react-photo-gallery';
import axios from 'axios';
// import Carousel, { Modal, ModalGateway } from "react-images";
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state={
      page: 'map',
      mapLoading: true,
      photos: [],
      viewerOpen: null
    }
    this.map = null;
    this.mapDiv = null;
  }

  changePage=(page)=>{
    this.setState({
      page: page
    })
  }

  initMap=()=>{
    this.map = new window.google.maps.Map(this.mapDiv, {
      center: {lat: 39.809734, lng: -94.555620},
      zoom: 3,
      mapTypeId: 'terrain'
    });
    this.getNewKML();
  }

  getInstagramPhotos=()=>{
    axios.get("https://storage.googleapis.com/storage/v1/b/kylefoley.appspot.com/o")
      .then(e=>{
        let photoArray = [];
        for(let photo of e.data.items){
          const img = new Image();
          const src = "https://storage.googleapis.com/"+photo.bucket+'/'+photo.name;
          img.onload = function() {
            let newPhoto = {
              src: src,
              height: this.height,
              width: this.width,
              // caption: photo.description
            }
            photoArray.push(newPhoto)
          }
          img.src = src
        }
        photoArray.sort((a,b)=>{
          const aNumber = parseInt(a.name.slice('_', '.'))
          const bNumber = parseInt(b.name.slice('_', '.'))
          if(aNumber < bNumber) return -1
          else if(aNumber > bNumber) return 1;
          else return 0;
        })
        this.setState({
          photos: photoArray
        })
      })
      .catch(e=>{
        console.log(e)
      })
  }

  getNewKML=()=>{
    if(!this.map){
      this.initMap();
      return;
    }
    const src = "https://share.garmin.com/feed/Share/KyleFoley?time="+Date.now();
    let kmlLayer = new window.google.maps.KmlLayer(src, {
      suppressInfoWindows: false,
      preserveViewport: true,
    });
    kmlLayer.setMap(this.map)
    setTimeout(()=>{
      kmlLayer.setMap(null)
      this.getNewKML()
    },60000)
    this.setState({
      mapLoading: false
    })
  }

  toggleImage=(event, img)=>{
    this.setState({
      viewerOpen: img ? img.index : null
    })
  }

  componentDidMount() {
    this.getInstagramPhotos();
    setTimeout(()=>{
      this.initMap();
    },1000)
  }


  render(){
    return (
      <div className="app">
          {/*{this.state.viewerOpen !== null &&*/}
          {/*<ModalGateway>*/}
          {/*  <Modal onClose={()=>this.toggleImage(null, null)}>*/}
          {/*    <Carousel*/}
          {/*      currentIndex={this.state.viewerOpen}*/}
          {/*      views={this.state.photos.map(x => ({*/}
          {/*        ...x,*/}
          {/*      }))}*/}
          {/*    />*/}
          {/*  </Modal>*/}
          {/*</ModalGateway>*/}
          {/*}*/}
          <div className={'header'}>
            <div className={'name'}>
              <p>Kyle Foley</p>
            </div>
            <div className='categories'>
              <div
                onClick={()=>this.changePage('map')}
                className={'category ' +(this.state.page === 'map' ? 'pageActive' : '')}
              >
                <p>Location</p>
              </div>
              {/*<div*/}
              {/*  onClick={()=>this.changePage('instagram')}*/}
              {/*  className={'category ' +(this.state.page === 'instagram' ? 'pageActive' : '')}*/}
              {/*>*/}
              {/*  <p>Photos</p>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className={'page'}>
            {this.state.mapLoading &&
              <div className='loader'>
                <img src={Loader} alt={'Loading'}/>
                <p>Loading...</p>
              </div>
            }
            <div
              className={'map ' + (this.state.page !== 'map' ?  'pageHidden' : '')}
              ref={div=>this.mapDiv=div}
            />
            {/*<div*/}
            {/*  className={'instagram ' + (this.state.page !== 'instagram' || this.state.mapLoading ?  'pageHidden' : '')}*/}
            {/*>*/}
            {/*  <Gallery photos={this.state.photos} onClick={this.toggleImage}/>*/}
            {/*</div>*/}
          </div>
        </div>
    );
  }
}

export default App;